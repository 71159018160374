import React from "react";

const HeaderView: React.FC = () => {
    return (
        <>
            <header id="header" className="header d-flex align-items-center fixed-top">
                <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
                    <a href="/" className="logo d-flex align-items-center">
                        <img className="" src="template/imgs/SA-Logo.png" alt="SA Infolines Logo" />
                        {/* <img className="" src="template/imgs/logo1.png" alt="SA Infolines Logo" /> */}
                        <h1 className="sitename">Infolines</h1>
                    </a>
                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li className="nav-btn"><a href="#hero">Home</a></li>
                            <li className="nav-btn"><a href="#about">About</a></li>
                            <li className="nav-btn"><a href="#details">Products</a></li>
                            <li className="nav-btn"><a href="#contact">Contact</a></li>
                        </ul>
                        <i className="mobile-nav-toggle d-xl-none bi bi-list" />
                    </nav>
                </div>
            </header>

        </>
    )

}

export default HeaderView;